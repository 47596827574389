@use "../../styles/partials/colors" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/global" as *;

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  width: 100%;

  @include tablet {
    padding: 0 4rem;
  }

  @include desktop {
    padding: 0;
    width: 50rem;
  }
}

.hero {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  margin: 5rem 0 10rem;

  @include tablet {
    margin: 5rem 0 10rem;
    width: 80%;
  }

  &__image {
    width: 80%;

    @include tablet {
      width: 36%;
    }
  }

  &__text {
    position: relative;

    z-index: 10;
    text-align: left;
    :nth-child(2) {
      padding: 0 0 2rem;
    }
    animation-duration: 2s;
    animation-name: slideinText;

    @keyframes slideinText {
      from {
        transform: translateX(-100%);
      }

      to {
        transform: translateX(0%);
      }
    }

    &__p1 {
      padding: 1rem 0;

      @include tablet {
        padding: 0 0 2rem;
      }
    }

    &__p2 {
      line-height: 1.5rem;

      @include tablet {
        line-height: 2.5rem;
        font-size: 22px;
      }
    }
  }

  &__link {
    text-decoration: none;
    box-shadow: inset 0 -3px 0 0 $tea-green;
    transition: all 0.2s ease-in-out;
    color: $black;
    font-weight: 700;

    &:hover {
      box-shadow: inset 0 -33px 0 0 $tea-green;
    }
  }
}

.circle {
  z-index: 1;
  position: absolute;
  height: 16rem;
  width: 16rem;
  background-image: linear-gradient(
    to right,
    rgba(242, 234, 252, 0),
    rgba(242, 234, 252, 1)
  );
  border-radius: 50%;
  display: inline-block;
  animation-duration: 2s;
  animation-name: slidein;

  @include tablet {
    height: 24rem;
    width: 24rem;
  }

  @keyframes slidein {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(0%);
    }
  }
}

.feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;

  @include tablet {
    margin-top: 6rem;
  }

  &__title {
    width: fit-content;
    position: relative;
    margin: 4rem 0;

    &__text {
      z-index: 10;
      position: relative;
    }

    &__line {
      border: 0.75rem solid $tea-green;
      width: 0;
      position: absolute;
      top: 0.75rem;
      left: 1rem;
      z-index: 1;
      transform-origin: bottom left;
      animation: easeInFromLeft 1s ease forwards;

      @include tablet {
        top: 1.25rem;
      }

      @keyframes easeInFromLeft {
        to {
          width: 100%;
        }
      }
    }
  }
}

.feature-container {
  display: flex;
  flex-direction: column;
}

.feature-work {
  width: 100%;
  margin: 2rem;
  display: flex;
  align-items: center;
}

.feature-image {
  background: $black;
  width: 24rem;
  height: 16rem;
  border-radius: 24px;

  &__file {
    width: 24rem;
    height: 16rem;
    object-fit: cover;
    border-radius: 20px;

    &:hover {
      opacity: 0.8;
    }
  }
}

.feature-text {
  padding: 0 3rem;

  &__link {
    text-decoration: none;
    color: $black;
  }

  &__title {
    margin-bottom: 1rem;
    text-align: left;
    width: fit-content;
    border-bottom: 3px solid $tea-green;

    &:hover {
      border-bottom: 3px solid $green;
    }
  }
}

.tech {
  display: flex;
  flex-wrap: wrap;
  padding-top: 0.75rem;

  &__item {
    list-style-type: none;
    border-right: solid 3px $tea-green;
    width: auto;
    padding: 0.25rem 0.5rem;

    &:last-of-type {
      border-right: none 3px $tea-green;
    }

    &:first-of-type {
      padding-left: 0;
    }
  }
}

.story {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;

  &_content {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include tablet {
      margin-top: 2rem;
      flex-direction: row;
      align-items: flex-start;
    }
  }

  &__text {
    width: 100%;
    margin: 2rem 0 0 0;

    @include tablet {
      margin: 0 3rem 0 0;
      width: 70%;
    }
  }
}

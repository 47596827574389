@use "../../styles/partials/colors" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/global" as *;

.moviepop {
  &-client {
    @include tablet {
      display: flex;
      align-items: flex-start;
    }

    &__title {
      text-align: center;
      width: 100%;
      padding-bottom: 1rem;

      @include tablet {
        text-align: left;
      }
    }
  }

  &-statement {
    display: flex;
    padding-top: 4rem;
    justify-content: space-between;

    &__subcat {
      width: 45%;

      &__title {
        text-align: center;
        padding-bottom: 0.75rem;

        @include tablet {
          text-align: left;
        }
      }
    }
  }

  &-details {
    display: flex;
    flex-direction: column;
    padding-top: 4rem;
    justify-content: space-around;
    width: 100%;

    @include tablet {
      flex-direction: row;
    }

    &__subcat {
      @include tablet {
        :first-of-type {
          padding-right: 1.5rem;
        }
      }

      @include tablet {
        :last-of-type {
          padding-left: 1.5rem;
        }
      }

      &__title {
        text-align: center;
        padding-bottom: 0.75rem;

        @include tablet {
          text-align: left;
        }
      }

      &__text {
        padding-bottom: 2rem;

        @include tablet {
          padding-bottom: 0;
        }
      }
    }
  }

  &-research {
    text-align: left;
    display: flex;
    flex-direction: column;
    width: 100%;

    &__title {
      padding-bottom: 0.75rem;
    }

    &__content {
      display: flex;
      flex-direction: column;
    }

    &__link {
      color: $black;
      font-style: italic;
      margin: 1rem 0 4rem;
      font-size: 13px;

      @include tablet {
        font-size: 15px;
      }
    }

    &__analysis-image {
      width: 100%;
      padding-bottom: 4rem;
    }

    &__image {
      width: 100%;
      padding-bottom: 2rem;

      @include tablet {
        width: 45%;
        padding-bottom: 3rem;
      }

      &__container {
        display: flex;
        flex-direction: column;
        padding: 1rem 0;

        @include tablet {
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-around;
          padding: 2rem 0;
        }
      }
    }
  }

  &-category {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      padding-bottom: 0.75rem;
      align-self: flex-start;

      &__persona {
        padding: 5rem 0 0.75rem;
        align-self: flex-start;
      }
    }

    &__text {
      text-align: left;

      &__impact {
        text-align: left;
      }
    }

    &__image {
      width: 100%;
      padding: 2rem 0;

      &__padding {
        width: 100%;
        padding: 1rem 0 4rem;

        @include tablet {
          padding: 2rem 0 6rem;
        }
      }
    }

    &__link {
      color: $black;
      font-style: italic;
      margin: 2rem 0;
      font-size: 13px;

      @include tablet {
        font-size: 15px;
      }
    }

    &__quote {
      font-style: italic;
      margin: 1rem 0 4rem;
      padding: 0 1.5rem;
      border-left: 1px solid $black;

      @include tablet {
        margin: 1rem 0;
      }
    }
  }

  &-ideation {
    display: flex;
    flex-direction: column;

    @include tablet {
      flex-direction: row;
    }

    &__info {
      &__title {
        padding-bottom: 0.75rem;
      }

      &__text {
        text-align: left;
      }
    }

    &__image {
      width: 100%;
      padding: 2rem 0;

      @include tablet {
        width: 75%;
        padding: 0 0 0 2rem;
      }
    }
  }

  &-access {
    display: flex;
    flex-direction: column;
    text-align: left;
    line-height: 1.5rem;
  }

  &-forward {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include tablet {
      flex-direction: row;
    }
    &__content {
      @include tablet {
        width: 45%;
      }
    }
  }
}

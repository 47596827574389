@use "../../styles/partials/colors" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/global" as *;

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 4rem 0 2rem;
  padding: 2rem;
  width: 100%;

  @include tablet {
    flex-direction: row;
    align-items: flex-start;
    margin: 8rem 0 4rem;
    width: 80%;
  }

  @include desktop {
    width: 50rem;
  }

  &-container {
    display: flex;
    justify-content: center;
  }

  &__links {
    display: flex;

    &__item {
      padding-right: 1rem;
      color: $black;

      &:hover {
        transform: scale(110%);
        transition-duration: 0.5s;
      }
    }
  }

  &__text {
    width: 100%;
    padding-top: 1rem;

    @include tablet {
      width: 60%;
      padding-top: 0;
    }

    @include desktop {
      width: 50%;
    }
  }
}

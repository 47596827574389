@use "../../styles/partials/colors" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/global" as *;

.projects {
  &__hero {
    width: 100%;
    border-radius: 1.25rem;
    margin-bottom: 1.5rem;
  }

  &__subheader {
    margin: 4rem 0;
    padding-bottom: 0.25rem;
    border-bottom: 0.5rem solid $tea-green;

    &:first-of-type {
      margin: 6rem 0;
    }

    @include tablet {
      padding-bottom: 0.5rem;
    }
  }
}

.keki {
  &-client {
    @include tablet {
      display: flex;
      align-items: flex-start;
    }

    &__title {
      text-align: left;
      width: 100%;
      padding-bottom: 1rem;
    }
  }

  &-statement {
    display: flex;
    flex-direction: column;
    padding-top: 4rem;
    justify-content: space-between;

    @include tablet {
      flex-direction: row;
    }

    &__subcat {
      width: 100%;

      @include tablet {
        width: 45%;
      }

      &__title {
        text-align: left;
        padding: 2rem 0 0.75rem;
      }

      &__text {
        text-align: left;
        padding-bottom: 2rem;

        &:last-of-type {
          padding-bottom: 0;
        }
      }
    }
  }

  &-details {
    display: flex;
    flex-direction: column;
    padding-top: 4rem;
    justify-content: space-between;
    width: 100%;

    @include tablet {
      flex-direction: row;
    }

    &__subcat {
      :first-of-type {
        padding-right: 1.5rem;
      }

      &__team {
        @include tablet {
          width: 35%;
        }
      }

      &__title {
        text-align: left;
        padding-bottom: 0.75rem;
      }

      &__text {
        text-align: left;
        padding-bottom: 2rem;

        @include tablet {
          padding-bottom: 0;
        }
      }
    }
  }

  &-research {
    text-align: left;
    display: flex;
    flex-direction: column;

    &__title {
      padding-bottom: 1rem;
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 2rem;

      @include tablet {
        flex-direction: row;
        width: 100%;
      }
    }
    &__summary {
      @include tablet {
        width: 100%;
        &:first-of-type {
          margin-right: 2rem;
        }
        &:last-of-type {
          margin-bottom: 2rem;
        }
      }
    }

    &__link {
      color: $black;
      font-style: italic;
      margin: 1rem 0 4rem;
      font-size: 13px;

      @include tablet {
        font-size: 15px;
      }
    }

    &__image {
      width: 100%;
      padding-bottom: 3rem;
    }
  }

  &-category {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      padding-bottom: 0.75rem;
      align-self: flex-start;
    }

    &__text {
      text-align: left;

      &__impact {
        padding-bottom: 2rem;
        text-align: left;

        @include tablet {
          padding-bottom: 0;
        }
      }
    }

    &__image {
      width: 100%;
      padding: 2rem 0;

      &__padding {
        width: 100%;
        padding: 1rem 0 4rem;

        @include tablet {
          padding: 2rem 0 6rem;
        }
      }
    }

    // &__link {
    //   color: $black;
    //   font-style: italic;
    //   margin: 2rem 0;
    //   font-size: 13px;

    //   @include tablet {
    //     font-size: 15px;
    //   }
    // }

    &__quote {
      font-style: italic;
      margin-top: 1rem;
      padding: 0 1.5rem;
      border-left: 1px solid $black;
    }
  }

  &-plan {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include tablet {
      flex-direction: row;
    }

    &__text {
      text-align: left;
      @include tablet {
        width: 45%;
      }
    }
  }

  &-ideation {
    display: flex;
    flex-direction: column;

    @include tablet {
      flex-direction: row;
    }

    &__info {
      &__title {
        padding-bottom: 0.75rem;
      }

      &__text {
        text-align: left;
      }
    }

    &__image {
      width: 100%;
      padding: 2rem 0;

      @include tablet {
        width: 75%;
        padding: 0 0 0 2rem;
        margin-bottom: 2rem;
      }
    }
  }

  &-access {
    display: flex;
    flex-direction: column;
    text-align: left;
    line-height: 1.5rem;

    @include tablet {
      flex-direction: row;
    }

    &__list {
      padding: 1rem 0 0 1rem;
      font-size: 14px;

      @include tablet {
        width: 75%;
        padding: 0;
        font-size: 16px;
      }

      li {
        padding-bottom: 0.5rem;

        @include tablet {
          padding-bottom: 0.75rem;
        }
      }
    }
  }

  &-forward {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include tablet {
      flex-direction: row;
    }
    &__content {
      @include tablet {
        width: 45%;
      }
    }
  }

  &-movie {
    margin-top: 2rem;

    &__clip {
      width: 80%;

      @include tablet {
        width: 40%;
      }
    }
  }

  &-impact {
    padding-top: 4rem;
    text-align: left;
  }

  &-list {
    text-align: left;
    line-height: 1.25rem;
    font-size: 14px;
    padding: 1rem 0 2rem 0;
    list-style-position: inside;

    @include tablet {
      line-height: 1.5rem;
      font-size: 16px;
      padding: 0.5rem 0 0 0;
    }

    li {
      padding-bottom: 0.5rem;

      @include tablet {
        padding-bottom: 0.75rem;
      }
    }
  }
}

@use "../../styles/partials/colors" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/global" as *;

.header-container {
  display: flex;
  justify-content: center;
}

.header {
  display: flex;
  flex-direction: column;
  padding: 3rem 1rem;
  width: 100%;

  @include tablet {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    padding: 5rem 1rem;
  }

  @include desktop {
    width: 50rem;
  }

  &__logo {
    height: 3rem;
    margin-bottom: 2rem;

    @include tablet {
      height: 4rem;
      margin-bottom: 0;
    }

    &:hover {
      transform: scale(110%);
      transition-duration: 0.5s;
    }
  }
  &__nav {
    display: flex;
    justify-content: space-around;
    align-items: end;

    & a {
      padding: 0;
      text-decoration: none;
      color: $black;
      cursor: pointer;

      @include tablet {
        padding: 0 2rem;
      }

      &:hover {
        // color: $green;
        transform: translate(0, -4px);
        transition-duration: 0.3s;
      }

      // &.active {
      //   color: $green;
      // }
    }
  }
}

.nav-item {
  display: flex;
  align-items: center;

  &__link {
    height: 1.25rem;
    width: 1.25rem;
  }
}

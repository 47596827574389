@use "../../styles/partials/colors" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/global" as *;

.botanic {
  &__article {
    margin-bottom: 5rem;
  }

  &__subtitle {
    text-align: center;
    padding-bottom: 1rem;

    @include tablet {
      text-align: left;
      padding-bottom: 2rem;
    }
  }

  &__sketch {
    width: 60%;
    padding-bottom: 2rem;
  }

  &__full-image {
    width: 100%;
    padding-bottom: 2rem;
  }
}

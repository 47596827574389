@use "../../styles/partials/colors" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/global" as *;

.goTopHidden {
  display: none;
}

.goTop {
  display: block;
  &__text {
    position: fixed;
    color: $tea-green;
    background-color: white;
    border-radius: 50px;
    width: 2.5rem;
    height: 2.5rem;
    bottom: 5%;
    left: 80%;
    cursor: pointer;

    @include tablet {
      left: 90%;
    }

    &:hover {
      transform: scale(1.1);
      color: darken($tea-green, 6%);
    }
  }
}

@font-face {
  font-family: "CartoGothic";
  src: url("../../assets/fonts/CartoGothic\ Std\ Book.otf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "CartoGothic";
  src: url("../../assets/fonts/CartoGothic\ Std\ Italic.otf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "CartoGothic";
  src: url("../../assets/fonts/CartoGothic\ Std\ Bold.otf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "CartoGothic";
  src: url("../../assets/fonts/CartoGothic\ Std\ Bold\ Italic.otf")
    format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Mermaid";
  src: url("../../assets/fonts/Mermaid.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Lora";
  src: url("../../assets/fonts/Lora-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@use "../../styles/partials/colors" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/global" as *;

.about {
  @include desktop {
    flex-direction: row;
    align-items: flex-start;
  }

  &__photo {
    width: 80%;
    border-radius: 0.5rem;
    margin-top: 2rem;

    @include tablet {
      width: 45%;
      margin-top: 0;
    }
  }

  &__text {
    padding-top: 2rem;

    @include desktop {
      padding-left: 2rem;
      padding-top: 0;
    }

    &__content {
      padding-bottom: 1rem;

      &:last-of-type {
        padding-bottom: 3rem;
      }
    }

    &__link {
      text-decoration: none;
    }
  }
}

@use "../../styles/partials/colors" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/global" as *;

.projects {
  &__title {
    padding-bottom: 0.5rem;
    border-bottom: solid 2px $black;
    margin: 2rem 0 4rem;
  }
}

.projects-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @include tablet {
    flex-direction: column;
    align-items: flex-start;
  }
}

.project {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;

  @include tablet {
    margin: 3rem 0;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__links-and-title {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
  }

  &__links {
    // padding-top: 0.5rem;
    display: flex;
    // flex-direction: column;
    :nth-child(2) {
      padding-left: 1rem;
    }
  }
}

.project-image {
  cursor: pointer;
  overflow: hidden;
  // width: auto;
  // height: fit-content;

  @include tablet {
    width: 50%;
  }

  &__file {
    object-fit: cover;
    width: 100%;
    transform: scale(1);
    transition-timing-function: ease-in-out;
    transition-duration: 0.2s;

    &:hover {
      transform: scale(1.02);
    }

    @include tablet {
      height: 16rem;
      width: 100%;
    }
  }
}

.project-text {
  text-align: left;
  padding: 2rem 0 3rem;
  display: flex;
  flex-direction: column;

  @include tablet {
    padding: 0 0 0 2rem;
    width: 50%;
  }

  &__link {
    text-decoration: none;
    color: $black;
  }

  &__subtitle {
    margin-bottom: 1rem;
    text-align: center;
    font-weight: 700;
    text-decoration: underline;
    letter-spacing: 0.0156rem;

    &:hover {
      color: $green;
    }
  }

  &__description {
    margin: 1rem 0;
  }
}

@use "./colors" as *;
@use "./variables" as *;
@use "./fonts" as *;

@mixin tablet {
  @media (min-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop-breakpoint) {
    @content;
  }
}

.pill {
  font-size: 0.8125rem;
  font-weight: 600;
  border: 2px solid $tea-green;

  border-radius: 1.5rem;
  width: auto;
  padding: 0.25rem 0.75rem;
  margin: 0.25rem;
}

.button {
  padding: 0.75rem 1.5rem;
  border-radius: 20px;
  color: $black;
  border: 2px solid $tea-green;
  background-color: $tea-green;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;

  @include tablet {
    font-size: 16px;
  }

  &:hover {
    background: darken($tea-green, 6%);
    border: 2px solid darken($tea-green, 6%);
  }

  &-w-link {
    background-color: white;
    display: flex;
    align-items: center;

    &__icon {
      height: 1.5rem;
      width: 1.5rem;
      padding: 0;
      margin: 0;
    }

    &::after {
      content: url("../../assets/icons/external-link.svg");
      display: inline-block;
      margin-left: 0.5rem;
      width: 1rem;
      height: 1rem;
    }
  }

  &-anchor {
    text-decoration: none;
  }
}
